<template>
    <div>
        <base-header base-title="Ubah Password"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">

                        </div>
                    </div>
                </div>
                <form class="kt-form kt-form--label-right" id="myForm">
                    <div class="kt-portlet__body mt-1" >
                        <div class="row mb-4">
                            <label class="col-lg-4"></label>
                            <div class="col-lg-6">
                                <h5 class="kt-section__title kt-section__title-sm">Ubah Atau Pulihkan Kata Sandi Anda:</h5>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Password Lama:</label>
                            <div class="col-lg-6">
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="user.currentPassword"
                                    v-validate="'required'"
                                    data-vv-as="Password Lama"
                                    name="oldPassword"
                                    :class="{'is-invalid': errors.has('oldPassword') }">
                                <div v-show="errors.first('oldPassword')" class="invalid-feedback">{{ errors.first('oldPassword') }}</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Password:</label>
                            <div class="col-lg-6">
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="user.newPassword"
                                    v-validate="'required|min:4|max:50'"
                                    data-vv-as="Password"
                                    ref="password"
                                    name="password"
                                    :class="{'is-invalid': errors.has('password') }"/>
                                <div v-show="errors.first('password')" class="invalid-feedback">{{ errors.first('password') }}</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-4 col-form-label">Konfirmasi Password:</label>
                            <div class="col-lg-6">
                                <input
                                    type="password"
                                    class="form-control"
                                    v-model="konfirm"
                                    v-validate="'required|confirmed:password'"
                                    data-vv-as="Konfirmasi Password"
                                    name="pass_conf"
                                    :class="{'is-invalid': errors.has('pass_conf') }"/>
                                <div v-show="errors.first('pass_conf')" class="invalid-feedback">{{ errors.first('pass_conf') }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__foot">
                        <div class="kt-form__actions">
                            <div class="row">
                                <div class="col-lg-4 col-xl-4">
                                </div>
                                <div class="col-lg-6 col-xl-6">
                                    <button type="button" @click="submit()" class="btn btn-brand btn-bold">Change Password</button>&nbsp;
                                    <button type="button" @click="reset()" class="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import SweetAlertTemplate from "./../_general/SweetAlert";
import BlockUI from "../_js/BlockUI";
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const UserRepository = RepositoryFactory.get("user");
export default {
    components: {
        BaseHeader,
        BaseTitle,
    },
    data() {
        var vx = this;
        return {
           headTitle: "Ubah Password",
           user:{
               currentPassword: null,
               newPassword: null
           },
           konfirm: null
        }
    },
    watch: {

    },
    methods: {
        reset: function() {
            this.user.currentPassword = null;
            this.user.newPassword = null;
            this.konfirm = null;
            this.errors.clear();
            this.$nextTick(() => {
                this.$validator.reset();
            });
        },
        submit: function(){
            var vx = this;
            this.$validator.validateAll().then(result => {
                if (result) {
                    let instance = new SweetAlert().showConfirm({
                    swalTitle: "Apakah Anda yakin?",
                    swalText: "Perubahan Password akan tersimpan.",
                    swalType: "info",
                    onConfirmButton: function () {
                        blockUI.blockModal();
                        vx.update(vx.user);
                    },
                    onCancelButton: function () {
                        blockUI.unblockModal();
                    }
                    });
                return;
                }
            });
        },
        update: function(payload){
            var vx = this;

            UserRepository.changePassword(payload)
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Perubahan Password berhasil disimpan.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                            vx.reset();
                        }
                    });
                })
                .catch(error => {
                    var msg = ValidateErrorMessage.parseMessage(error);
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: msg,
                        swalType: "error",
                        onConfirmButton: function () {
                        blockUI.unblockModal();
                        }
                    });
                });
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {

    }
}
</script>
